import axios from "axios";
const config = {
  // BASE_URL: "http://192.168.1.23:5000/",
  BASE_URL: "https://admin.motivusi.com/",
};

const fetchClient = () => {
  const defaultOptions = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
      Accept: "application/json",
    },
  };
  let instance = axios.create(defaultOptions);

  return instance;
};

export const apiClient = {
  put(path, params) {
    return fetchClient().put(`${config.BASE_URL}${path}`, params);
  },
  get(path) {
    return fetchClient().get(`${config.BASE_URL}${path}`);
  },
  post(path, params) {
    return fetchClient().post(
      `${config.BASE_URL}${path}`,
      JSON.stringify(params)
    );
  },
  patch(path, params) {
    return fetchClient().patch(`${config.BASE_URL}${path}`, params);
  },
  delete(path) {
    return fetchClient().delete(`${config.BASE_URL}${path}`);
  },
  upload(path, params) {
    return fetchClient().post(`${config.BASE_URL}${path}`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
export default apiClient;
