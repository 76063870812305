import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
// import { AboutUsImg, Years } from "../images";
import { Logo, Logo2 } from "../images"
// import { logo-new} from "../images";

const AboutUs = () => {
  return (
    <>
      <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>About Us</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>About</li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="about-sec" style={{ padding: "70px 0px 130px" }}>
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="about-img">
                <img src={Logo2} alt="" />
                {/* <img className="years" src={} alt="" /> */}
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="about_content">
                <h3 className="heading">
                  <span>About Us</span>
                </h3>
                {/* <h2>Driving Change As A Disruptor</h2> */}
                <p>
                  Motivus Innovation Private Limited is a company dedicated to technological innovation and is based in Noida, India. Our services are available to clients throughout the entire country. Our team is composed of highly professional individuals with a combined experience of more than 150 years in various industries. These industries include, but are not limited to, Telecommunications, Renewable Energy, Power Electronics, Fibre, and Transmission. Our expertise extends beyond India to other South East Asian countries such as Malaysia, Myanmar, Bangladesh, Pakistan, Sri Lanka, Cambodia, Philippines, Indonesia, and Thailand. Through our experience in these regions, we have developed a deep understanding of the challenges and opportunities presented in these markets, which allows us to provide tailored and effective solutions to our clients.
                </p>
                <h1 className="sub-heading">
                  <span>About</span>
                </h1>
              </div>
            </Col>
            {/* <Col lg="12" md="12">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle text-undline">Motivus Innovation Group – Ongoing Projects :</h4>
                
              </div>
              <div className="challenges-content mt-3">
                <ul>
                  <li><b>Digital Battery Regeneration for BSNL, Airtel. Sterlite and V-Con Towers:</b> Motivus Team has been regenerating used and scrapped Lead Acid batteries and extending their life and capacity by 90% of their original standard at a fraction of the cost of a new battery. </li>
                  <li><b>Smart Battery Monitoring System:</b> Motivus has been installing Smart BMS for Lead Acid batteries to provide cell level monitoring of various parameters to predict behaviour and avoid failure.</li>
                  <li><b>Supplies of Various Electronics Equipment:</b> Motivus has been supplying electrical and electronics equipment to various industries to support Renewable Energy.</li>
                </ul>
              </div>
            </Col>
            <Col lg="12" md="12">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle text-undline">Motivus Innovation Group – Projects under Development :</h4>
                
              </div>
              <div className="challenges-content mt-3">
                <ul>
                  <li><b>Processed Bamboo Poles: </b> Motivus Team wishes to replace GI based poles with Processed Bamboo. Shifting from GI based poles to bamboo poles will reduce Carbon Emissions by 35%, CAPEX by 70%, and Deployment time by 90%. </li>
                  <li><b>Compressed Compost Poles:</b> Motivus Team also wishes to work on developing Towers and Poles made from various kind of Compost. Crushing waste building material, and bio compost, drying them and using chemical bonding and compression to create pre-fabricated towers that can be used to replace Transmission, Electricity and Telecom poles.</li>
                  <li><b>Reengineering on Digital Battery Regeneration: </b> Motivus team is in the process of developing newer algorithms to improve and further fine-tune the efficiencies of the machine to improve the success ratio of regeneration of the lead acid batteries.</li>
                  <li><b>Developing VR Based Training Gear: </b> Motivus team wishes to develop VR based training gear to facilitate up skilling and up scaling of resources for 5G.</li>
                </ul>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
