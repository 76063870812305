import React, { useEffect, useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import Carousel from "react-bootstrap/Carousel";
import apiClient from "../Config/apiConfig";
import { LineWave } from "react-loader-spinner";
import { Link } from "react-router-dom";
const config = {
  BASE_URL: "https://admin.motivusi.com",
};

const Slider = () => {
  const [getBannerData, setGetBannerData] = useState([]);
  const [loader, setLoader] = useState(true);
  const bannerApi = async () => {
    try {
      const res = await apiClient.get(`api/banners`);
      setGetBannerData(res.data);
      setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    bannerApi();
  }, []);
  return (
    <>
      <Carousel fade className="banner">
        {loader ? (
          <div className="d-flex justify-content-center">
            <LineWave
              height="250"
              width="150"
              color="#56b13d"
              ariaLabel="line-wave"
            />
          </div>
        ) : (
          getBannerData.map((item, i) => {
            return (
              <Carousel.Item key={i}>
                <img
                  className="d-block w-100"
                  src={`${config.BASE_URL}/uploads/banners/${item.thumbal}`}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h2 className="animate fadeInUp one">{item.name}</h2>
                  <h6 className="animate fadeInUp two">{item.desc}</h6>
                  {item.hyperlink ? (
                    <div className="slider-btn">
                      <Link
                        className="animate fadeInUp three"
                        to={item.hyperlink}
                        target="_blank"
                      >
                        <HiArrowNarrowRight />
                        Read More
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            );
          })
        )}
      </Carousel>
    </>
  );
};

export default Slider;
