import React from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Products from "../Components/Products";
import { FaUserTie } from "react-icons/fa";
import { Slider, Branches } from "../Components";
import OwlCarousel from "react-owl-carousel";
import {
  AboutUsImg,
  Battery,
  ServiceImg,
  WhoWeAre,
  Years,
  Certifications,
  CertificationsOne,
  CertificationsTwo,
  CertificationsThree,
  CertificationsFour,
  CertificationsFive,
  CertificationsSix,
  Association1,
  Association2,
  Association3,
  Association4,
  Association5,
  Association6,
  Supportingus1,
  Supportingus2,
  Supportingus3,
  Supportingus4,
  Supportingus5,
  Logo2,
  Smps,
  Vr,
  Blind,
  Batt,
  Under
  

} from "../images";
import {Digi} from "../images";
import { Link } from "react-router-dom";

const Home = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    loop: true,
    dots: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 1,
      },
      1024: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
  const clients = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    autoplay: false,
    loop: true,
    dots: false,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 2,
      },
      430: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 4,
      },
      1024: {
        items: 6,
      },
    },
  };
  return (
    <>
      <Slider />
      <div className="about-sec">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="about-img">
                <img src={AboutUsImg} alt="" />
                {/* <img className="years" src={Years} alt="" /> */}
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="about_content">
                <h3 className="heading">
                  <span>About Us</span>
                </h3>
                {/* <h2>Driving Change As A Disruptor</h2> */}
                <p>
                  Motivus Innovation Private Limited as a technology innovation company. Head Quartered in Noida we serve customers Pan India. A highly professional management, with a collective experience of more than 150 years in various industries spanning Telecommunications, Renewable Energy, Power Electronics, Fibre, and Transmission, in India as well as other South East Asian countries, like Malaysia, Myanmar, Bangladesh, Pakistan, Sri Lanka, Cambodia, Philippines, Indonesia and Thailand.
                </p>
                <Link to="about-us">Read More</Link>
                <h1 className="sub-heading">
                  <span>About</span>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="service-section branches-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Our Services</h4>
              {/* pbmit-subtitle  */}
              <div className="pbmit-heading-desc">The company is involved in identifying operational challenges in various industries and providing Innovative Solutions. We have the following solutions in our portfolio currently:</div>
            </div>
          </Row>
          <Row>
            <OwlCarousel className="slider-items owl-carousel owl-home" {...options}>

              <div className="item battery">
                <Products image={Battery} 
                title={"Battery Regeneration"}  style={{ height:"250px"}}/>
              </div>

              <div className="item">
                <Products
                  image={Digi}
                  title={"Digital Battery Regeneration"}
                // desc={
                //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                // }
                />
              </div>
              <div className="item">
                <Products
                  image={Smps}
                  title={"SMPS Core Upgradation"}
                // desc={
                //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                // }
                />
              </div>
              <div className="item">
                <Products
                  image={Under}
                  title={"Underground DG Bunkers"}
                // desc={
                //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                // }
                />
              </div>
              <div className="item">
                <Products
                  image={Batt}
                  title={"Battery Management System for VRLA Batteries"}
                // desc={
                //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                // }
                />
              </div>
              <div className="item">
                <Products
                  image={Blind}
                  title={"IOT Based Blind Sticks and Wheel Chairs"}
                // desc={
                //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                // }
                />
              </div>
              <div className="item">
                <Products
                  image={Vr}
                  title={"VR Gear based Training Modules"}
                // desc={
                //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                // }
                />
              </div>
              <div className="item">
                <Products
                  image={Logo2}
                  title={"Fleet Management Systems"}
                // desc={
                //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                // }
                />
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </div>
      <div className="statements-section">
        <Container>
          <Row>
            <Col sm="12">
              <div className="tab-sec">
                <Tabs defaultActiveKey="Mission" id="uncontrolled-tab-example">
                  <Tab eventKey="Mission" title="Mission statements">
                    <Row className="justify-content-end">
                      <Col md="12" lg="6">
                        <div className="content-box">
                          <h5>Mission Statement</h5>
                          {/* <h3>Company wants to achieve</h3> */}
                          <p>
                            To be the most preferred Solutions Provider, by offering innovative, comprehensive and customized solutions, services & products in the domain of Power, Infrastructure and Software.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="Vision" title="Vision statements">
                    <Row className="justify-content-end">
                      <Col md="12" lg="6">
                        <div className="content-box">
                          <h5>Vision Statement</h5>
                          {/* <h3>Company wants to achieve</h3> */}
                          <p>
                            To constantly innovate, build and deliver superior technological solutions for industry challenges that offer the highest level of reliability, efficiency & safety.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="Value" title="Core Values">
                    <Row className="justify-content-end">
                      <Col md="12" lg="6">
                        <div className="content-box">
                          <h5>Core Values</h5>
                          {/* <h3>Company wants to achieve</h3> */}
                          <p><i><strong className="color-logo">Ability to Listen: </strong></i>
                            Motivus believes in keeping the ears open to listen to the issues plaguing the industries and identifying gaps.
                          </p>
                          <p><i><strong className="color-logo">Innovation: </strong></i>
                            Motivus constantly innovates to find out solutions to the challenges and deliver on timely manner to customers.
                          </p>
                          <p><i><strong className="color-logo">Integrity: </strong></i>
                            Motivus ensures fairness, honesty and ethical behaviour in all that we do towards all of our stakeholders.
                          </p>
                          <p><i><strong className="color-logo">Excellence: </strong></i>
                            Motivus always pursues excellence and promotes the best standard of quality possible.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="service-section association-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Our Supporting Us</h4>
            </div>
          </Row>
          <Row>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Supportingus1} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus2} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus3} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus4} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus5} alt="Supporting Us" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="service-section">
        <Branches />
      </div>

      <div className="service-section association-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Our Association</h4>
            </div>
          </Row>
          <Row>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Association1} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association2} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association3} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association4} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association5} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association6} alt="Association Logo" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>
      {/* who we are sec start */}
      {/* <div className="who-we-are">
        <Container>
          <Row>
            <Col lg="5" md="12">
              <div className="who-we-are-img">
                <img
                  src="https://st.ourhtmldemo.com/new/Energo/assets/images/resource/chooseus-1.jpg"
                  alt=""
                />
                <div className="text">
                  <h3>who we are</h3>
                </div>
              </div>
            </Col>
            <Col lg="7" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Pravin Dubey</h5>
                  <i>
                    <p>
                      <strong className="color-logo">Executive Director and Mentor</strong> - Business
                      Owner, Startup Thought Leader, and Angel Investor.
                    </p>
                  </i>
                  <p>
                    Mr. Pravin Dubey is an engineering graduate with a passion
                    for mentoring startups. He has an extensive background in
                    the service sector, where he handled clients from various
                    industries such as technology, FMCG, aviation, finance, and
                    healthcare for over 25 years. After exiting from various
                    ventures, he now runs an angel fund and works with space
                    technology and agriculture. Praveen's expertise has been
                    instrumental in shaping and supporting the Fintech, Telecom,
                    Agriculture, and Agtech sectors in India and abroad over the
                    last decade. Through various incubation centers, he has
                    helped these sectors succeed and take on the technological
                    requirements of modern India.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="5" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Dipanjan Mukherjeey</h5>
                  <i>
                    <p>
                      <strong className="color-logo">Director</strong> - Business Manager with a strong
                      background in Telecommunications Operations and
                      Engineering.{" "}
                    </p>
                  </i>
                  <p>
                    With over 26 years of experience working in multiple Telecom
                    companies across India, Malaysia, Myanmar, and other
                    Southeast Asian countries, this individual possesses
                    comprehensive knowledge of Telecom Operations. Their
                    specialization lies in developing business strategies and
                    operational plans to achieve business goals. They possess a
                    strong vision and have been credited with successfully
                    innovating out of the box strategies and operational
                    excellence which have significantly impacted the
                    profitability of several profit center units.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="7" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Ravi Shankar</h5>
                  <i>
                    <p>
                      <strong className="color-logo">Director</strong> - Telecom Operation Specialist,
                      Expertise in Power electronics, New product development,
                      Batteries Storage( VRLA & Lithium-ion), Renewal Energy,
                      RMS, Regeneration & Refurbishment of Batteries,
                      Rectifiers, Power plant, and Diesel Generators.{" "}
                    </p>
                  </i>
                  <p>
                    With over 24 years of experience in telecom active and
                    passive operations and team building across diverse
                    environments and cultures, this diligent O&M personnel is
                    highly proficient in planning, RFQ, product quality testing,
                    and coordinating and executing successful operational
                    strategies. They excel at improving operational stability,
                    efficiency, and profitability. This individual has worked on
                    multiple projects with various telecom operators and tower
                    companies including Edotco Group Myanmar, Applied Solar
                    Technologies, VIOM Network, Aircel Limited, Airtel, Bharti
                    Infratel Limited, ACME, HFCL, and Exicom.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      {/* End who we are sec */}

      <div className="who-we-are">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Brief  Introduction of Management</h4>
            </div>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Dipanjan Mukherjee</h5>
                  <p>Over 26 years’ of professional experience in Telecom and Power Sector, of which the last 15 years at CXO/Director levels. Has experience of managing live network in India and 9 other countries in South East Asia.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Ravi Shankar</h5>
                  <p>
                    Over 25 years’ of professional experience in Telecom and Power Sector, with reputed organizations like Aircel, Bharti Infratel, Exicom etc. Last 4 years was Operations Head for Edotco Group, in Myanmar, which is globally the 6th largest Tower Company.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Amit Jain</h5>
                  <p>
                    Over 20 years’ of experience in Active Telecom Networks and Software. Have been associated with global organizations such has Ericsson, Nokia, Nutek and Crest. Has vast experiences in developing IOT and AI based software solutions.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Jugal Kishore</h5>
                  <p>
                    Over 28 years’ of professional exposure in the fields of Civil and Structural Designing and Project Rollout. Significant experience in various infrastructure industries, which includes construction of 1500+ apartments, mini townships, 42000+ telecom towers and railways projects.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Preeti Varshney</h5>
                  <p>
                    Over 15 years’ experience in Quality Control, Audit and Manufacturing. She is a Six Signa Blackbelt from South Korea. She has worked with large companies like Samsung and Uniworld.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>Aungsuman Chowdhury</h5>
                  <p>
                    Over 12 years’ of professional expertise in the fields of Electrical and Electronics innovation and development. He has managed large scale Workshops in countries outside India.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="service-section challenges">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="img-box">
                <img src={WhoWeAre} alt="" />
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle">OUR Challenges</h4>
                <h2 className="pbmit-title">
                  The challenges faced by the facility management are quite
                  significant.
                </h2>
              </div>
              <div className="challenges-content">
                <ul>
                  <li>The tenant has high power requirements</li>
                  <li>
                    99.999% uptime requirement, with no outages acceptable.
                  </li>
                  <li>High cost per GB of data generation.</li>
                  <li>
                    Frequent replacement CAPEX needed for lead-acid batteries.
                  </li>
                  <li>
                    No cell-level monitoring available for lead-acid batteries
                    leading to large-scale abuse and pilferage.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="certifications-sec">
        <Container className="borders-sec">
          <Row>
            <Col sm="12">
              <h2>Certifications</h2>
            </Col>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Certifications} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsOne} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsTwo} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsThree} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsFour} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsFive} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsSix} alt="" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="fun-section centred green-bg">
        <span className="big-text">Fun Numbers</span>
        <div className="bg-layer">
          <div className="bg-1"></div>
          <div className="bg-2"></div>
        </div>
        <Container>
          <Row>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>2600</span>
                </div>
                <h5>Projects Completed</h5>
                <div className="icon-box">
                  <GiFilmProjector />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>137</span>
                </div>
                <h5>Professional Staff</h5>
                <div className="icon-box">
                  <FaUserTie />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>09</span>
                </div>
                <h5>Countries Covered</h5>
                <div className="icon-box">
                  <FaGlobeAmericas />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>845</span>
                </div>
                <h5>Satisfied People</h5>
                <div className="icon-box">
                  <FaPeopleArrows />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default Home;
