import React from "react";
// import { BsSearch } from "react-icons/bs";
// import { FaShoppingBag } from "react-icons/fa";
// import { AiOutlineHeart } from "react-icons/ai";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const Products = ({ image, title, desc }) => {
  return (
    // <div className="single-product">
    //   <div className="product-img">
    //     <img src={image} alt="" />
    //     <div className="product-category">
    //       <div>
    //         <AiOutlineHeart style={{ marginRight: "20px" }} />
    //         <BsSearch />
    //       </div>
    //       <div>
    //         <FaShoppingBag style={{ margin: "-3px 8px 0px 0px" }} />
    //         shop now
    //       </div>
    //     </div>
    //   </div>
    //   <div className="product-title">
    //     <h5>{title}</h5>
    //   </div>
    //   <div className="product-price">
    //     <span>{name}</span>
    //     <b>{price}</b>
    //   </div>
    // </div>
    <>
      <div className="product-item">
        <div className="service-img-wrapper">
          <div className="featured-wrapper">
            <img src={image} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="box-content">
          <div className="box-content-inner">
            <h3 className="service-title">
              <a href="/">{title}</a>
            </h3>
            <div className="service-content">
              <p>{desc}</p>
            </div>
            <div className="service-btn">
            <Link to="/products" >
                <span>
                  View More <HiOutlineArrowNarrowRight />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
