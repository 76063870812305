import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import apiClient from "../Config/apiConfig";
import { LineWave } from "react-loader-spinner";
const config = {
  BASE_URL: "https://admin.motivusi.com",
};
const Products = () => {
  const [getProducts, setGetProducts] = useState([]);
  const [loader, setLoader] = useState(true);
  const getAllProduct = async () => {
    try {
      const res = await apiClient.get(`api/products`);
      setLoader(true);
      setGetProducts(res.data.data.product);
      setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getAllProduct();
  }, []);
  return (
    <>
      <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>Our Companies</h1>
            </div>
            <ul className="bread-crumb our-company pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>Our Companies</li>
            </ul>
          </Row>
        </Container>
      </div>
      {loader ? (
        <div className="d-flex justify-content-center">
          <LineWave
            height="250"
            width="150"
            color="#56b13d"
            ariaLabel="line-wave"
          />
        </div>
      ) : (
        <>
          {getProducts.map((item, i) => {
            const number = i + 1;
            if (number % 2 === 1) {
              return (
                <div className="product-inner-page" key={i}>
                  <Container>
                    {/* //product name start with */}
                    <Row>
                      <Col sm="12">
                        <div className="product-inner-title">
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <h2 className="mb-0">{item.name}</h2>
                            {item.link ? (
                              <div className="products-btn">
                                <Link to={item.link} target="_blank">
                                  Contact Us
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <p>{item.desc}</p>
                        </div>
                      </Col>
                    </Row>
                    {item?.product_faqs.length > 0 ? (
                      <Row id={`imgparentwrap${item.id}`}>
                        <Col lg="7">
                          <div className="accorHeight" id="scroll-bar">
                            <Accordion defaultActiveKey="0" flush>
                              {item?.product_faqs.map((productfaq, j) => {
                                return (
                                  <Accordion.Item eventKey={j + 1} key={j}>
                                    <Accordion.Header
                                      onClick={() =>
                                        showprojectImage(
                                          `${productfaq.id}, ${productfaq.product_id}`
                                        )
                                      }
                                    >
                                      {productfaq.question}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {productfaq.answer}
                                      {productfaq.url ? (
                                        <div className="products-btn faq">
                                          <Link
                                            to={productfaq.url}
                                            target="_blank"
                                          >
                                            Contact Us
                                          </Link>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })}
                            </Accordion>
                          </div>
                        </Col>
                        <Col lg="5">
                          {item?.product_faqs.map((productfaqimg, k) => {
                            return (
                              <img
                                src={`${config.BASE_URL}/uploads/product/${productfaqimg.image}`}
                                alt=""
                                className={`showimg imgid${productfaqimg.id} ${
                                  k === 0 ? "" : "d-none"
                                }`}
                              />
                            );
                          })}
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Container>
                </div>
              );
            } else {
              return (
                <div className="product-inner-page" key={i}>
                  <Container>
                    {/* //product name start with */}
                    <Row>
                      <Col sm="12">
                        <div className="product-inner-title">
                          <h2>{item.name}</h2>
                          <p>{item.desc}</p>
                        </div>
                      </Col>
                    </Row>

                    {item?.product_faqs.length > 0 ? (
                      <Row id={`imgparentwrap${item.id}`}>
                        <Col lg="5">
                          {item?.product_faqs.map((productfaqimg, k) => {
                            return (
                              <img
                                src={`${config.BASE_URL}/uploads/product/${productfaqimg.image}`}
                                alt=""
                                className={`showimg imgid${productfaqimg.id} ${
                                  k === 0 ? "" : "d-none"
                                }`}
                              />
                            );
                          })}
                        </Col>
                        <Col lg="7">
                          <div className="accorHeight" id="scroll-bar">
                            <Accordion defaultActiveKey="0" flush>
                              {item?.product_faqs.map((productfaq, j) => {
                                return (
                                  <Accordion.Item eventKey={j + 1} key={j}>
                                    <Accordion.Header
                                      onClick={() =>
                                        showprojectImage(
                                          `${productfaq.id}, ${productfaq.product_id}`
                                        )
                                      }
                                    >
                                      {productfaq.question}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {productfaq.answer}
                                      {productfaq.url ? (
                                        <div className="products-btn faq">
                                          <Link
                                            to={productfaq.url}
                                            target="_blank"
                                          >
                                            Contact Us
                                          </Link>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })}
                            </Accordion>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Container>
                </div>
              );
            }
          })}
        </>
      )}
    </>
  );
};
const showprojectImage = (id) => {
  const explodedArray = id.split(", ");
  const productitemid = explodedArray[0];
  const productid = explodedArray[1];
  const imagediv = document.getElementById("imgparentwrap" + productid);
  const elements = imagediv.getElementsByClassName("showimg");
  console.log(elements.length);
  for (let i = 0; i < elements.length; i++) {
    elements[i].classList.add("d-none");
  }
  imagediv
    .getElementsByClassName("imgid" + productitemid)[0]
    .classList.remove("d-none");
  imagediv
    .getElementsByClassName("imgid" + productitemid)[0]
    .classList.remove("d-block");
};

export default Products;
